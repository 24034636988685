<template>
	<v-container>
		<h1>{{ module.name }}</h1>
		<ul>
			<li
				v-for="lesson in module.lessons"
				:key="lesson.id">
				{{ lesson.name }}
			</li>
		</ul>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { apiFactory } from '../api/apiFactory'

const modulesApi = apiFactory.get('modules')

export default {
	props: {
		moduleid: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		module: {},
	}),
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		async loadModule(moduleid) {
			try {
				const { data } = await modulesApi.get(moduleid)
				this.module = data
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити модуль',
					color: 'error',
				}, { root: true })
			}
		},
	},
	mounted() {
		this.loadModule(this.moduleid)
	},
}
</script>